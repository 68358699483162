body {
  background-color: #F5F5F5;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
