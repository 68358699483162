/* Bubbles.css */
.bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }
  
  .bubble {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 152, 0, 0.3);  /* Orange, semi-transparent */
    border-radius: 50%;
    position: absolute;
    bottom: -80px;
    animation: float 10s infinite ease-in-out;
  }
  
  .bubble:nth-child(2) {
    width: 60px;
    height: 60px;
    left: 20%;
    animation-duration: 8s;
  }
  
  .bubble:nth-child(3) {
    width: 80px;
    height: 80px;
    left: 40%;
    animation-duration: 12s;
  }
  
  .bubble:nth-child(4) {
    width: 50px;
    height: 50px;
    left: 60%;
    animation-duration: 10s;
  }
  
  .bubble:nth-child(5) {
    width: 30px;
    height: 30px;
    left: 80%;
    animation-duration: 14s;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-1000px);
      opacity: 0;
    }
  }
  